import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PageHelmet from 'components/Helmets/PageHelmet';
import PageTitle from 'components/PageTitle';
import Image from 'components/PreviewCompatibleImage';
import { AboutSection, TextColumn } from 'style/components';
import Content, { HTMLContent } from '../components/Content';

export const AboutPageTemplate = ({
  title,
  content,
  image,
  ContentComponent,
  helmet,
}) => (
  <AboutSection>
    {helmet}
    <PageTitle id="about-title">{title}</PageTitle>
    <TextColumn id="about-text">
      <ContentComponent content={content} />
    </TextColumn>
    <Image imageInfo={image} />
  </AboutSection>
);

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  image: PropTypes.object.isRequired,
  ContentComponent: PropTypes.func,
  helmet: PropTypes.node,
};

AboutPageTemplate.defaultProps = {
  content: ``,
  ContentComponent: Content,
  helmet: null,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <AboutPageTemplate
      ContentComponent={HTMLContent}
      title={post.frontmatter.title}
      image={post.frontmatter.image}
      content={post.html}
      helmet={(
        <PageHelmet
          pageTitle={post.frontmatter.title}
          path={post.fields.slug}
        />
      )}
    />
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 700, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
